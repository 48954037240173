    // Header scroll class
    $(window).scroll(function () {
        if ($(this).scrollTop() > 5) {
         //  $('#header').addClass('header-scrolled');
        } else {
     
        //   $('#header').removeClass('header-scrolled');
        }
    });






 

